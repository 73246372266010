








































import { Component, Watch } from "vue-property-decorator"
import { mixins } from "vue-class-component"
import ProductApi from "@/api/Product/ProductApi"
import _ from "lodash"
import { deserialize } from "typescript-json-serializer"
import qs from "qs"
import ProductGroup from "../../types/Product/ProductGroup"
import BreakpointMixin from "../../types/BreakpointMixin"
import CompareProductsBar from "@/components/Products/CompareProductsBar.vue"

@Component({
  name: "ProductGroupList",
  components: { CompareProductsBar }
})
export default class ProductGroupList extends mixins(BreakpointMixin) {
  private productGroups: ProductGroup[] = []

  @Watch("$route", { immediate: true, deep: true })
  onUrlChange() {
    // Router from vue-router
    this.fetchData()
  }

  mounted() {
    this.fetchData()
  }

  private fetchData() {
    localStorage.removeItem("productFilter")
    ProductApi.listProductGroups().then(({ data }) => {
      this.productGroups = _.map(data, (d) => deserialize<ProductGroup>(d, ProductGroup))
      this.productGroups = this.productGroups.filter(
        (productGroup: ProductGroup) =>
          productGroup.title !== "CALCULATION" &&
          productGroup.title !== "คำนวณวัสดุ" &&
          productGroup.id !== 23 &&
          productGroup.id !== 24
      )
    })
  }

  // private viewProductGroup(group: ProductGroup) {
  //   // eslint-disable-next-line @typescript-eslint/camelcase
  //   localStorage.setItem("productFilter", qs.stringify({ product_group: [group.id] }))
  //   const lang = this.$i18n.locale
  //   this.$router.push({
  //     name: "ProductCategoryList",
  //     params: { id: `${group.id}` },
  //     query: { lang }
  //   })
  // }

  private generateRoute(group: ProductGroup) {
    return {
      name: "ProductCategoryList",
      params: { id: `${group.id}` },
      query: { lang: this.$i18n.locale }
    }
  }

  private get imageHeight() {
    const bp = this.$vuetify.breakpoint
    if (bp.xlOnly) {
      return 392
    }
    if (bp.mdAndUp) {
      return 275
    }
    return 275
  }

  private get imageWidth() {
    const bp = this.$vuetify.breakpoint
    if (bp.xlOnly) {
      return 396
    }
    if (bp.mdAndUp) {
      return 264
    }
    return 275
  }
}
